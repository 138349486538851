
import "./price.scss";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";

// kartinki
import checkingImg from "../../asset/price/checking.png"
import changingImg from "../../asset/price/changing.png"
import mountingImg from "../../asset/price/mounting.png"
import meterImg from "../../asset/price/meter.svg"
import searchImg from "../../asset/price/search.svg"
import wrenchImg from "../../asset/price/wrench.svg"
import zamena_chetchikovImg from "../../asset/price/zamena_chetchikov.png"
import promuvka_filtrovImg from "../../asset/price/promuvka_filtrov.png"




function Price() {
  const [modalActive, setModalActive] = useState(false);
    return (
      <>
        <main>
          <Modal active={modalActive} setActive={setModalActive}></Modal>
            <h2 className="body__title">Цены на оказываемые услуги</h2>
            <section className="price__item">
              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={checkingImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={searchImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                <h2>Заказать поверку счетчика без снятия (на месте) - многоквартирный дом  </h2>
                 <h4>Стоимость услуги (1ед.) ....  <span>450 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>

              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={changingImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={meterImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                <h2>Заказать поверку на месте (без снятия счётчика) - частный сектор </h2>
                 <h4>Стоимость услуги (1ед.) ....  <span>500 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>

              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={changingImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={wrenchImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                <h2>Заказать поверку на месте (без снятия счётчика) - г.Новоульяновск  </h2>
                 <h4>Стоимость услуги (1ед.) ....  <span>550 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>

              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={zamena_chetchikovImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={wrenchImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                <h2>Заказать замену счетчика (счетчик Ваш - гарантия только на установку)</h2>
                 <h4>Стоимость услуги (1ед.) ....  <span>1500 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>

              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={zamena_chetchikovImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={searchImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                <h2>Заказать замену счетчика (счетчик наш, с гарантией на - 1 год)</h2>
                <h4>Стоимость услуги (1ед.) ....  <span>2650 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>

              <div className="price__item__cart">
                <div className="price__item__cart__images">
                  <img className="price__item__cart__images_img1" src={promuvka_filtrovImg} alt="checkingImg" />
                  <img className="price__item__cart__images_img2" src={meterImg} alt="searchImg" />
                </div>
                <div className="price__item__cart__title">
                 <h2>Заказать техническое обслуживание фильтра грубой очистки</h2>
                 <h4>Стоимость услуги (1ед.) ....  <span>450 руб.</span></h4>
                </div>
                <div className="price__item__cart__btn"><a onClick={()=>setModalActive(true)}>Заказать поверку</a></div>
              </div>
              {/* <table className="price__item__table">
              <tr>
                <th>Наименование услуги</th><th>Цена</th>
              </tr>
              {priceObj?.map((item) =>
              <tr><td>{item.title}</td><td>{item.cost}</td></tr>
              )}
              </table> */}
              
            </section>
        </main>
      </>
    );
  }
  
  export default Price;
  